import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NjY2OTU3NzMsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2w5ZHd1bXNiMG1ybDAxdWlncnBjNnMxcy9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNTlhMTg3ZTYtMzk0My00YjBmLWFhOTctYTYyOTdmOTQ2YTAyIiwianRpIjoiY2w5bzNtcDc3MDF5ejAxdXJmNnRoMGdxZiJ9.ygsns8jbbcL6wB0ZbIa_xi-cfXKzbN0mxyEigQjXRHqPP1CyWXUMEXeWY8AiG86f3LEpL5og3JXRlJfrbn05PoK_JWlUeD370nDyXsFGX60NPj0K4fy5wFDTMhAy95S2hOcXounHz8SfAwBocvj31f34vTQ6FIq4VIX91QkS3C4taZSAlMFoOMYjHJLCIm0R5lSD7kSHWP6DOVbiBFbp7BN63NZ9GyuBGBH0G9lngwx6Yu3i0iDEoftfk6zVTAuT-7MF2qOVzjDT4gzRCdeNo7JFN6bKROXelBEzYceK3Sszl-N7UpXRNQPLDJX78vx3j-ZrbjOKBJkNwhoaUTtPxEoCIio3cwVnjsemnUx06C8hyMFmke-cgsrRF-L4eFRUgjRZ6_BoJ_Zj3tKTOGIdOQTZDLqUwy_o4KCs3f5ngMuOYAf8uGBzCpG6ztYb6AnVaIy5K86Gnm2Dm7kvNDq8Lz5vMTVlsfj49fAWVo9Cn-wfjTyYLb-llDhu7Jr5uyI3N4k3VAtV4y2l3i8UR3SeXSTHozwrm2Pb89Nh40zEcefQSqoKIUPOftJ9dgMFOcVDvHI3G-vgObIxIgwQ8aSjNGJxwgnWZ3-_2-0xqQMWju9Fr3qdQF0Ped4TgMNN2WFk57m3iAdAqcCJkEVFuEyPLiVnz-m5b2CzAR54IOI87VI';

const client = new ApolloClient({
	uri: 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cl9dwumsb0mrl01uigrpc6s1s/master',
	headers: {
		authorization: `Bearer ${token}`
	},
	cache: new InMemoryCache(), 
	fetch
});

export default client;