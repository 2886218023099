
import React from 'react';

import { ApolloProvider } from '@apollo/client';

import client from './src/apollo/client';


export const wrapPageElement = ({ element }) => (
	<ApolloProvider client={client}>
		{element}
	</ApolloProvider>
);

